@import './../../variables.scss';


.social-networks.hover-color {
    ul {
        li {
            a.gray-scale {
                width: 2.5rem;
                height:  2.5rem;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;

                @media(max-width: $sizeSm){
                    width: 1.8rem;
                    height:  1.8rem;
                }
            }
            a.facebook.gray-scale {
                background-image: url('./../../images/icons/facebook-icon-gray.png');
                &:hover {
                    background-image: url('./../../images/icons/facebook-icon.png');
                }
            }
            a.twitter.gray-scale {
                background-image: url('./../../images/icons/twitter-icon-gray.png');
                &:hover {
                    background-image: url('./../../images/icons/twitter-icon.png');
                }
            }
            a.linkedin.gray-scale {
                background-image: url('./../../images/icons/linkedin-icon-gray.png');
                &:hover {
                    background-image: url('./../../images/icons/linkedin-icon.png');
                }
            }
            a.youtube.gray-scale {
                background-image: url('./../../images/icons/youtube-icon-gray.png');
                &:hover {
                    background-image: url('./../../images/icons/youtube-icon.png');
                }
            }
        }
    }
}

.main {
    .social-networks {
        ul {
            li {
                &:not(:last-child) {
                    margin-right: 5rem;
                }
            }
        }
    }
}

.testimonials {
    background-image: url('./../../images/nosotros/team-bg.png');
    background-size: cover;
    p {
        font-size: 1.25rem;
    }
    .owl-theme {
        .owl-nav {
            top: calc(50% - 3.125rem);
        }
    }
}
.offices {
    h3 {
        font-size: 1.625rem;
    }

    h2{
        @media(max-width: $sizeSm){
            font-size: 1.525rem!important;
        }
    }
    .btn-filled {
        &:after {
            background-image: url("./../../images/icons/filled-btn-arrow-gray.png") !important;
        }
        &:hover {
            &:after {
                background-image: url("./../../images/icons/filled-btn-arrow.png") !important;
            }
        }
    }
}
@media(max-width: 1200px){
    .offices .text-smaller,
    .offices .text-smaller span{
        font-size: 0.85rem!important;
    }
}

.core-values{
    background-image: url('./../../images/nosotros/core-values-bg.png');
    background-size:cover;
    background-position: center;
    color: #fff;
    font-size: 1.25rem;

    h2{
        @media(max-width: $sizeSm){
            font-size: 1.58rem !important;
        }
    }
}

.core-values {
    background-image: url('./../../images/nosotros/core-values-bg.png');
    background-size: cover;
    background-position: center;
    color: #fff;
    font-size: 1.25rem;
    .icons {
        background-image: url('./../../images/nosotros/dark-transparency.png');
    }
    h6 {
        font-size: 1.125rem;
    }
    .icon-value {
        height: 7.813rem;
        width: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        margin-bottom: 1.875rem;
    }
}

@media(max-width: $sizeMd){
    .main .social-networks ul li:not(:last-child){
        margin-right: 1.25rem;
    }
}

.testimonial-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.testimonial-text p{
    font-size: 1.125rem!important;
}
.nosotros p{
    font-size: 1.25rem;
    text-align: justify;

    @media(max-width: $sizeSm){
        & {
            line-height: 1.3;
            font-size: 0.93rem;
        }
    }
}

.about-more h4{
    font-weight: 700;
}

.about-more {
    @media(max-width: $sizeSm){
        margin-bottom: 3rem;
        img{
            max-width: 40vw;
        }
    }

    @media(min-width: $sizeSm){
        .img-zoom {
            .img-container {
                padding: 1.2rem;
                transition: all 300ms;
            }
            &:hover {
                .img-container {
                    padding: 0;
                }
            }
        }
    }
}
