.contador{
  @property --num {
    syntax: '<integer>';
    inherits: true;
    initial-value: 0;
  }

  div::before {
    counter-reset: my-counter var(--num);
    content: counter(my-counter);
    animation: count 10s ease-in-out infinite alternate;
  }

  @keyframes count {
    to { --num: 100; }
  }
}