@import './../../../variables.scss';

#new-header{
  background-size: 50%;
  .header-image{
    background: linear-gradient(45deg, #e4e2e2 60%, transparent 40%);
    @media(min-width: $sizeLg){
      background: linear-gradient(45deg, #e4e2e2 50%, transparent 50%);
    }
  }

  strong, p{
    font-size: 0.8rem;
  }

  @media(max-width: $sizeSm){
    h2{
      font-size: 1rem;
    }
  }
  @media(min-width: $sizeSm) and (max-width: $sizeMd){
    h2{
      font-size: 1.4rem;
    }
  }

  @media(min-width: $sizeMd){
    strong, p{
      font-size: 1rem;
    }
  }
}

.new-source{
  color: #0061a5!important;
  font-weight: 600;
  text-decoration: none;

  @media(max-width: $sizeMd){
    font-size: 1.1rem;
  }

}

.new-body{
  text-align: justify;

  @media(min-width: $sizeMd){
    column-count: 2;
    column-gap: 5rem;
  }
}
