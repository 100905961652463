
$clients: 'tcl', 'calimax', 'driscolls', "navico", "envases", "andrew-williamson", "parpro";


#listado-clientes{
  .grid {
    display: grid;
    gap: 0;
    grid-template-columns: repeat(3, 1fr);

    & > div{

      @each $client in $clients {
        .#{$client} {
          .flip-card-front,
          .flip-card-back{
            background-size: cover;
            background-position: center;
          }
          .flip-card-front{
            background-image: url("./../../../images/clientes/#{$client}.jpg");
          }

          .flip-card-back{
            background-image: url("./../../../images/clientes/#{$client}-active.jpg");
          }
        }
      }
    }
  }

}

.flip-card {
  background-color: transparent;
  width: 100%;
  height: 100%;
  perspective: 1000px;
  &:hover {
    .flip-card-inner {
      transform: rotateY(180deg);
    }
  }
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);

    .flip-card-front {
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      background-color: #bbb;
      color: black;
    }
    .flip-card-back {
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      transform: rotateY(180deg);
    }

  }

}