@import './../../../variables.scss';

#operaciones{
  background-image: url("./../../../images/grupo/bg-operaciones.jpg") !important;
  text-align: left;
  background-color: #FBCF4A;
  background-position: top right;
  background-repeat: no-repeat;
  background-size: cover;

  @media(max-width: $sizeMd){
    padding: 2vw 0;
    background-position: 30% top;
    text-align: justify;
  }

  @media(max-width: $sizeXl){
    padding: 4vw 0;
  }

  @media(min-width: $sizeXl){
    padding: 6vw 0;
  }

  .btn-filled.btn-rounded{
    background-color: #fff;
    border: none;
    padding: 0.5rem 2rem;
    border-radius: 2.3rem;

    &:after{
      background-repeat: no-repeat;
      background-image: url("./../../../images/icons/filled-btn-arrow-gray.png");
    }

    &:hover{
      background-color: #000!important;
      color: #fff!important;

      &:after{
        background-image: url("./../../../images/icons/filled-btn-arrow-yellow.png")!important;

      }
    }
  }
}