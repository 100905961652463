@import './../../variables.scss';
.contact-strip{
    background-image: url("../../images/contact-strip-bg.png");
    color: #ffffff;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    position: absolute;
    bottom:0;
    width:100%;

    .mobile-details{
        font-size: 0.7rem;
        line-height: 1;
        text-align: left!important;
    }
}
.contact-strip .icon{
    background-repeat: no-repeat;
    padding-left: 5rem;

    @media(max-width: $sizeSm){
        padding-left: 1.15rem;

        background-position: left center;
        background-size: contain;
        background-repeat: no-repeat;
    }
}

.contact-strip .phone-call,
.contact-strip .home-location,
.contact-strip .at-email
{
    font-size: 1.188rem;
}

.contact-strip .phone-call strong,
.contact-strip .home-location strong,
.contact-strip .at-email strong
{
    font-size: 1.313rem;
}
@media(max-width: $sizeSm){
    .contact-strip .phone-call strong, .contact-strip .home-location strong, .contact-strip .at-email strong{
        font-size: 0.95rem!important;
    }
}
@media(max-width: $sizeSm){
    .contact-strip .phone-call,
    .contact-strip .home-location,
    .contact-strip .at-email
    {
        font-size: 0.92rem!important;
        line-height: 1;
    }

    .home-location{
        padding-left: 1rem!important;
    }
}

@media (min-width: $sizeSm) and (max-width: $sizeMd){
    .contact-strip .phone-call strong, .contact-strip .home-location strong, .contact-strip .at-email strong{
        font-size: 0.9rem!important;
    }
    .contact-strip .phone-call,
    .contact-strip .home-location,
    .contact-strip .at-email
    {
        font-size: 0.9rem;
        padding-left:1rem;
        background-size: 1.8rem;
        background-position: center left;
    }
}

@media (min-width: $sizeMd) and (max-width: 1400px){
    .contact-strip .phone-call strong, .contact-strip .home-location strong, .contact-strip .at-email strong{
        font-size: 1.15rem!important;
    }
    .contact-strip .phone-call,
    .contact-strip .home-location,
    .contact-strip .at-email
    {
        font-size: 1.15rem;
    }
}

.contact-strip .phone-call{
    background-image: url("../../images/icons/whats.png");
}

.contact-strip .home-location{
    background-image: url("../../images/icons/home-location.png");
}

.contact-strip .at-email{
    background-image: url("../../images/icons/at-email.png");
}

.contact-strip a{
    text-decoration: none;
}
.bordered-text{
    border:  1px  solid #fff;
    padding: 0.188rem 0.938rem;
    border-radius:  10px;
    font-weight: 600;
}

.bordered-transparent{
    border:  1px  solid #fff;
    padding: 0.188rem 0.938rem;
    border-radius:  10px;
    width: fit-content;
}

.exchange{
    font-weight: 600;
    align-items: center;
    display: flex;
    font-size: 1.063rem;

    .label {
        @media(max-width: $sizeSm){
            font-size: 1.4rem;
            padding-left: 1.2rem;
            text-align: left;
        }
        @media(min-width: $sizeSm){
            text-align: right;
            font-size: 1.765rem;
            padding-right: 1.7rem;

        }
    }

    .bordered-text{
        min-width: 6.25rem!important;
        display:block;
    }

    .price{
        color: #fcbf24;
        font-size: 1.25rem;
        line-height: 1.875rem;
    }

    .date{
        background-color: #575656;
        padding: 0.188rem 0.938rem;
        border-radius: 10px;
    }
}

@media(min-width: $sizeMd){
    .contact-strip .at-email{
        background-position: 3.8vw!important;
    }
    .contact-strip .icon{
        text-align: left;
        background-size: 2vw;
        background-position: 1.8vw;
    }
}


@media(max-width: $sizeMd){
    .contact-strip{
        text-align: left;
        background-position: 3.5vw;
    }
}

.vr{
    background-color: #777676;
}