@import './../../variables.scss';

.acceso{
  background-color: #000;
  background-position: center;
  background-image: url("/images/acceso/acceso-bg.png");
  color: #fff;

  &.freight,
  &.agencia-glr,
  &.transporte{
    background-size:cover;
    .description{
      h2{
        font-weight: 400;
        text-transform: uppercase;
        font-size: 1.4rem;
        margin-bottom: 2rem;
        font-family: Gotham, Arial, sans-serif;
      }
    }

    &.transporte{
      background-image: url("/images/acceso/bg-transportes.png");
    }

    &.agencia-glr{
      background-image: url("/images/acceso/bg-agencia.png");
    }

    &.freight{
      background-image: url("/images/acceso/bg-freight-forwarding.png");
    }

  }

  @media(max-width: $sizeMd){
    height: 100vh;
  }
  a{
    text-decoration: none;
  }
  .btn-access{
    border-radius: 0.6rem;
    line-height: 1.2;
    font-family: Gotham, Arial, sans-serif;
    font-size: 1.3rem;
    text-transform: uppercase;
    color: #946e00;
    background: linear-gradient(180deg, #fdcd45 50%, #fcbf24 50%);
    font-weight: 600;
    margin: 0 auto;
    display: block;
    padding: 0.188rem 1.5rem;
    text-decoration: none!important;

    @media(max-width: $sizeMd){
      font-size: 1.4rem;
      padding: 0.2rem 1.4rem;
    }
  }
}


.acceso-over {

  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  align-items: center;

  margin-bottom: 5rem;
  @media(max-width: $sizeSm){
    margin-bottom: 3rem;
    img{
      max-width: 40vw;
    }
  }

  @media(min-width: $sizeMd) and (max-width: $sizeLg){
    min-height: 40vw;
  }



  @media(min-width: $sizeSm){
    .img-zoom {
      .img-container {
        min-height: 44rem;
        padding: 1rem 0.6rem;
        transition: all 300ms;

        @media(min-width: $sizeSm) and (max-width: $sizeLg){
          min-height: 30vw;
        }
      }

      &:before{
        content: " ";
        height: 4.2rem;
        margin: 0 auto 0.5rem auto;
        width: 2.8rem;
        left: 0;
        right: 0;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        visibility:hidden;
        display:block;
        opacity: 0;
        transition:visibility 0.3s linear,opacity 0.2s linear;
        background-image: url("/images/acceso/option-active.png");
      }

      &:hover {
        &:before{
          visibility:visible;
          opacity: 1;
        }
        .img-container {
          padding: 0;
        }

        .btn-access{
          color: #fff;
        }
      }
    }
  }

  .img-zoom{
    width: 30%;
  }
}

.divisor-vertical-access:after {
  content: " ";
  background-color: black;
  min-height: 30rem;
  width: 3px;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  background-size: contain;
  display: block;
  top: 0;
  background-image: url("/images/acceso/vertical-divisor.png");
}


.divisor-vertical-access{
  flex: 0 0;
  max-width: 1px;
  position: relative;
  margin: 0 1rem;
  width: 1%;
}

.logo-agencia{
  height: calc(60vh - 130px);
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: nowrap;
}

.access-selection{
  div{
    display: inline-block;
    background-position: center;
    background-size:cover;
    margin-bottom: 1.5rem;
    width: 5rem;
    height: 5rem;
    background-repeat: no-repeat;

    &.arrow-left,
    &.arrow-right{
      background-size: 2rem;
      cursor: pointer;
    }

    &.arrow-left{
      background-position: left center;
      background-image: url("/images/acceso/arrow-left.png");
    }

    &.arrow-right{
      background-position: right center;
      background-image: url("/images/acceso/arrow-right.png");
    }

    &.world-icon{
      background-image: url("/images/acceso/option-world.png");
    }
  }
}