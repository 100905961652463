@import './../../../variables.scss';
#quienes-somos{
  text-align: justify;
  display:block;

  @media(min-width: $sizeMd){
    background-color: #e9f2f3;
  }

  @media(max-width: $sizeMd){
    .strip{
      background-color: #e5e5e5;

      img{
        max-width: 50vw;
      }
    }
  }

  img{
    margin-top: -1rem;
    @media(min-width: $sizeMd) and (max-width: $sizeXl - 1px){
      position:absolute;
      bottom:0;
      left:0;
    }
  }

  p{
    text-align: justify;
  }
}