@import './../../../variables.scss';

#almacen{
  margin: 0.2rem 0;
  background-image: url("./../../../images/grupo/bg-almacen.jpg") !important;
  color: #fff;
  text-align: right;
  background-position: center;
  background-size: cover;

  .contador{
    h6{
      color: #eec43f;
      font-size: 2.5rem;
      font-weight: 600;
      margin-bottom:0;
      padding-bottom:0;
      @media(max-width: $sizeMd){
        font-size: 2rem;
      }
    }

    @media(max-width: $sizeMd){
      p{font-size: 0.94rem; line-height: 1.2}
      h6{font-size: 2rem;}
    }
  }
}