@import './../../variables.scss';

.contacto{
  .main-header{
    .header-container{
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-around;

      @media(min-width: $sizeMd) and (max-width: $sizeMd){
        align-content: stretch;
        align-items: flex-end;
      }
      @media(min-width: $sizeLg){
        align-items: stretch;
        align-content: stretch;
      }
    }
  }
  h4{
    font-weight: 600;
    font-size: 1.8rem;
  }

  .text-medium{
    font-size: 1.65rem;
  }
}