.agencia-servicios{
  cursor: pointer;
  .servicio{
    background-color: gray;
    border-radius: 0.7rem;

    h5{
      font-weight: 600;
      color: #fcc023;
    }

    .servicio-front,
    .servicio-hover{
      display:flex;
      height: 100%;
      width: 100%;
      flex-direction: column;
    }

    .servicio-front{
      justify-content: center;
      align-items: center;
      visibility: visible;
    }
    .servicio-hover{
      display: flex;
      flex-direction: column;
      align-items: center;
      visibility: hidden;
      padding: 1.4rem 0.5rem;
    }

    &:hover{
      .servicio-front{
        visibility: hidden;
        display:none;
      }
      .servicio-hover{
        visibility: visible;
      }
    }
  }
}