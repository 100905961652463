@import 'variables.scss';

html {
    font-size: 100%;

    @media(max-width: $sizeSm){
        font-size: 80%;
    }
}

body{
    font-size: 16px;
    font-family: Arial;
}

a{
    cursor: pointer!important;
}

.dropdown-toggle{
    padding:0;
}

h2,h3{
    font-weight: 600;
}

h2{
    font-size: 2.3rem;

    @media(max-width: 678px){
        font-size: 2rem;
    }
}

h3{
    font-size: 2.4rem;
}

.btn-filled{
    border:1px solid #1d1d1b;
    border-radius: 8px;
    color: #1d1d1b;
    font-weight: 700;
    font-size: 1.2rem;
    background-color: transparent;
    text-decoration: none;

    @media(max-width: $sizeMd){
        font-size: 1rem;
    }
}

.btn-filled:hover,
.parent-hover:hover .btn-filled{
    background-color: #fcbf24;
    color:  #000;
    border-color: #fcbf24;
    cursor: pointer;
}

.btn-filled:not(.btn-no-arrow){
    padding: 0.188rem 0.938rem 0.188rem 1.25rem;
}

.btn-filled.btn-no-arrow{
    padding: 0.188rem 0.938rem 0.188rem 0.938rem;
}

.btn-filled:not(.btn-no-arrow):after{
    content:"";
    background-image: url("images/icons/filled-btn-arrow.png");
    width: 10px;
    height: 14px;
    margin-left: 10px;
    background-size: 7px;
    display: inline-block;
    vertical-align: middle;
    background-repeat: no-repeat;
}

.hr-yellow{
    background-color: #fcbf24;
    display: block;
    width: 100%;
    height: 2px!important;
    opacity: 1;
    border: 0;
}

@media(max-width: 992px){
    h3{
        font-size: 1.8rem;
    }
}

.text-yellow{
    color:#fcbf24!important;
}

.owl-theme .owl-nav{
    position: absolute;
    top: 50%;
    width:100%;
}

.owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next{
    margin:0;
    color: transparent!important;
}

.owl-carousel .owl-nav button.owl-prev:hover, .owl-carousel .owl-nav button.owl-next:hover{
    background-color: rgba(0,0,0,0.7)!important;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
    background: #fcbf24!important;
}

.owl-theme .owl-dots .owl-dot span{
    background: #747070!important;
}

.owl-theme .owl-dots{
    margin-top: 15px;
    margin-bottom: 15px;
}

.owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next{
    position: absolute;
}

.owl-carousel .owl-nav span{
    position: relative;
    top: -8px;
}

.owl-carousel .owl-nav button.owl-prev{
    left:0;
}
.owl-carousel .owl-nav button.owl-prev:before,
.owl-carousel .owl-nav button.owl-next:before{
    content: "";
    background-color: rgba(0,0,0,0.7)!important;
    color: #fff!important;
    line-height: 125px!important;
    width: 30px;
    margin: 0!important;
    border-radius: 0!important;
    display: block;
    height: 120px;
    background-position: center;
    background-size: 23px;
    background-repeat: no-repeat;
}

.owl-carousel .owl-nav button.owl-prev:before{
    background-image: url("images/icons/slider-arrow-left.png");
}

.owl-carousel .owl-nav button.owl-next:before{
    background-image: url("images/icons/slider-arrow-right.png");
}

.owl-carousel .owl-nav button.owl-next{
    right:0;
}

@media(max-width: $sizeMd){
    .owl-theme .owl-nav{
        width: 100%;
    }
}

.divisor-diagonal{
    flex: 0 0;
    min-width: 40px;
    background-image: url("images/icons/diagonal-separator.png");
    display:block;
    min-height: 40px;
    background-size: auto 35px;
    background-position: 10px center;
    background-repeat: no-repeat;
}

.divisor-vertical,
.divisor-vertical-yellow,
.divisor-vertical-gray {
    flex: 0 0;
    max-width: 1px;
    position: relative;
    margin: 0 20px;
}


.divisor-vertical:after,
.divisor-vertical-yellow:after{
    content: " ";
    min-height: 55px;
    background-size: 25px;
    background-position: right center;
    background-repeat: no-repeat;
    background-color: #f2f2f1;
    width: 1px;
    position: absolute;
    top:50px;

    @media(max-width: 768px) {
        top: 10px;
        min-height: 65px;
    }
}

.divisor-vertical-gray:after{
    content: " ";
    min-height: 100%;
    background-size: 25px;
    background-position: right center;
    background-repeat: no-repeat;
    background-color: gray;
    width: 1px;
    position: absolute;
    top: 0;

    @media(max-width: 768px) {
        top: 10px;
        min-height: 65px;
    }
}

.text-right{
    text-align: right;
}
.transparency{
    opacity: 0.7;
}

.divisor-vertical-yellow:after{
    background-color: #fcbf24!important
}

input[type="radio"]
{
    appearance: none;
}

input[type="radio"] + label
{
    color: #fff;
    cursor: pointer;
    position: relative;
    padding-left: 1.875rem;
    line-height: 1.25rem;

    @media(max-width: $sizeSm){
        padding-left: 1.3rem;
    }
}

input[type="radio"]:hover + label::before,
input[type="radio"]:checked + label::before{
    background: #fff!important;

    @media(max-width: $sizeSm){
        background: #fcbf24!important;
    }
}

input[type="radio"] + label::before
{
    content: "";
    display: inline-block;
    width: 1.25rem;
    aspect-ratio: 1;
    border: 0px solid #fff;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    opacity: 1;
    transition: all 0.3s;

    @media(max-width: $sizeSm){
        width: 0.8rem;
    }
}

input[type="radio"] + label::after
{
    content: "";
    display: inline-block;
    width: 0.625rem;
    aspect-ratio: 1;
    border: 1px solid #fcbf24!important;
    background: #fcbf24!important;
    border-radius: 50%;
    position: absolute;
    left: 0.313rem;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    transition: all 0.3s;

    @media(max-width: $sizeSm){
        display:none;
    }
}

input[type="radio"]:checked + label::after,
input[type="radio"]:hover + label::after
{
    opacity: 1;
}

.rounded{
    border-radius: 10px!important;
}

.centered-dots .owl-dots{
    position: absolute;
    bottom: 0;
    margin: 0 auto;
    left: 0;
    right: 0;
}

.owl-large-dots .owl-dots .owl-dot span{
    width: 0.938rem!important;
    height: 0.938rem!important;

    @media(max-width: $sizeSm){
        width: 0.5rem!important;
        height: 0.5rem!important;

        margin: 0.5rem 0.2rem 1rem 0.2rem!important;
        background-color: #fff!important;

    }
}

.slick-dots li{
    margin: 0 0.188rem;
}

.slick-dots li button:before {
    font-size: 0.75rem;
    opacity: 1;
    color: #a3a1a2!important;
}

.slick-dots li.slick-active button:before,
.slick-dots li:hover button:before{
    color: #fcbf24!important
}

.slick-arrow,
.slick-arrow:focus,
.slick-arrow:hover{
    background-color: rgba(0,0,0,0.7);
    height: 6.563rem;
    width: 1.875rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1.563rem;
}

.slick-arrow.slick-prev{
    background-image: url("images/icons/slider-arrow-left.png");
}

.slick-arrow.slick-next{
    background-image: url("images/icons/slider-arrow-right.png");
}

.slick-next:before, .slick-prev:before{
    content: " ";
}
.slick-next.slick-disabled, .slick-prev.slick-disabled{
    opacity: 0;
}

.slick-prev {
    left: 0;
    z-index: 1;
}

.slick-next {
    right: 0;
    z-index: 1;
}
.slick-next:before{
    content:  ""
}

.text-smaller{
    font-size: 1rem!important;
}


@media (min-width: 1400px){
    .container.smaller-container, .container-lg.smaller-container, .container-md.smaller-container, .container-sm.smaller-container, .container-xl.smaller-container, .container-xxl.smaller-container {
        max-width: 1160px;
    }
}

.bg-light-blue{
    background-color: #e9f2f3;
}

body{
    padding-right: 0!important;
}
.btn{
    &.btn-enviar{
        border:none;
        margin-top: 1rem;
        background-color: #1d1d1b;
        color: #fff;
        border-radius: 0;
        font-weight: 500;
        padding: 0.7rem 3.1rem 0.7rem 1.4rem;
        background-image: url("images/icons/btn-contacto-flecha-right-white.png");
        background-repeat: no-repeat;
        background-position: calc(100% - 0.6rem) center;
        background-size: 1.45rem;
        margin-bottom: 0!important;
        &:hover,
        &:active{
            color: #1d1d1b;
            background-image: url("images/icons/btn-contacto-flecha-right.png");
            background-color: #fcbf24!important
        }
    }
}

.form-response{
    display: flex;
    align-items: center;
    justify-content: center;
}

.Mui-select{
    &.Mui-select-required{
        label:after{
            content: " *";
            color: #db3131;
        }
    }
}

.MuiMenu-list{
    background-color: #e6e5e5;
    padding:0;
    li{
        &:hover{
            background-color: #cdcdcd;
            &.Mui-selected{
                background-color: #cdcdcd;
            }
        }
        &.Mui-selected{
            background-color: #c8c8c8;
        }

        &:not(:last-child){
            border-bottom: 1px solid #5e5d5d;
        }
    }
}

.font-weight-light{
    font-weight: 200;
}

.font-weight-bold{
    font-weight: 600;
}

.success-center{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h4, p{
        font-weight: 600;
        line-height: 1.2;
    }
}

.bg-light-gray{
    background-color: #e5e5e5;
}