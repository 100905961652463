#youtube-embed{

  padding-top: 2rem;
  border-radius: 2rem;
  border: 2px solid #000;

  .yt-controls{
    padding: 1.5rem;
    display:flex;
    align-items: center;

    .yt-play{
      border: 0;
      background: transparent;
      box-sizing: border-box;
      width: 0;
      height: 2.3rem;
      margin-right: 1rem;

      border-color: transparent transparent transparent #202020;
      transition: 100ms all ease;
      cursor: pointer;

      // play state
      border-style: solid;
      border-width: 1.156rem 0 1.156rem 1.875rem;

      &.pause{
        border-style: double;
        border-width: 0px 0 0px 1.875rem;
      }

      &:hover{
        border-color: transparent transparent transparent #404040;
      }
    }
    .yt-progress{
      width: 100%;
      position: relative;
      height: 0.5rem;
      content: " ";
      display:block;
      background-color: #000;

      .yt-progress-bar{
        height: 0.5rem;
        content: "";
        display:block;
        background-color: red;
        position: absolute;
      }

      //&:after{
      //  height: 0.8rem;
      //  content: " ";
      //  display:block;
      //  background-color: #000;
      //  width: calc(100% - 2rem);
      //}
    }

  }


}

.ytp-show-cards-title{
  display:none!important;
}