@import './../../../variables.scss';

.grupo-solucion{
  text-align: center;
  position: relative;
  p{
    min-height: 5rem;

    @media(max-width: $sizeSm){
      font-size: 0.62rem;
      line-height: 1;
      min-height: 1.8rem;
    }

    @media(min-width: $sizeSm) and (max-width: $sizeMd){
      font-size: 0.8rem;
      line-height: 1.2;
      min-height: 3rem;
    }
  }
  img{
    margin-bottom: 1rem;

    @media(min-width: $sizeMd){
      max-height: 10rem;
      width: auto;
    }

    @media(max-width: $sizeSm){
      width: auto;
      max-height: 15vw;
    }

    @media(max-width: $sizeMd){
      width: auto;
      max-height: 10vw;
    }
  }

  &:hover{
    &:after{
      content: "";
      width: 60%;
      height: 0.6rem;
      left:0;
      bottom:0;
      background-color: #fcbf24;
      display:block;
      margin:0 auto;
      position: absolute;
      right:0;
    }
  }
}

.ventaja-competitiva{
  img{
    @media(max-width: $sizeSm){
      max-width: 30vw;
    }
    @media(max-width: $sizeMd){
      height: 16vw;
    }
    @media(min-width: $sizeMd){
      max-height: 8vw;
    }
    width: auto!important;
  }
}