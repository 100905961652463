@import './../../../variables.scss';
$buttons: 'outlook', 'whatsapp', 'formulario';

#solicita-cotizacion{

  @media(max-width: $sizeLg){
    .container{
      max-width: 100%;
    }
  }

  img{
    @media(max-width: $sizeLg){
      bottom: 0;
      position: absolute;
      right: 0;
      max-height: 35vw;
      width: auto!important;
    }

    @media(min-width: $sizeMd){
      margin-top: -2rem;
    }
  }

  @media(max-width: $sizeMd){
    h2{
      font-size: 1.3rem;
    }

    p{
      font-size: 0.88rem;
    }
  }

  @media(max-width: $sizeSm){
    h2{
      font-size: 1rem;
    }

    p{
      padding:0;
      font-size: 0.5rem;
    }
  }


  .btn-rounded{
    border-radius: 3rem;
    background-size: 100%;
    display:block;
    background-position: center;
    background-repeat: no-repeat;
    margin: auto;

    @media(max-width: $sizeMd){
      min-width: 8rem;
      height: 1.5rem;
      margin-bottom: 0.3rem;
    }

    @media(min-width: $sizeMd) and (max-width: $sizeLg){
      min-width: 10rem;
      height: 2rem;
      margin-bottom: 0.3rem;
    }

    @media(min-width: $sizeLg){
      min-width: 15rem;
      height: 3rem;
      margin-bottom: 0.5rem;

    }
    @each $button in $buttons {
      &.#{$button} {
        background-image: url("./../../../images/clientes/#{$button}.jpg");

        &:hover{
          transition: background-image 100ms;
          background-image: url("./../../../images/clientes/#{$button}-active.jpg");
        }
      }
    }
  }
}