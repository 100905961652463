@import './../../variables.scss';
header .container{
    max-width: 1140px!important;
}
.navbar{
    transition: background-color 200ms, height 200ms;
}

.navbar .navbar-brand img{
    transition: height 200ms ease-in-out;
}

.navbar.scroll{
    position: fixed;
    width: 100%;
    top:0;
    z-index:9;
    background-color: rgba(0,0,0,0.6);
}

.navbar.scroll .navbar-brand img{
    height: 6.25rem;
}

.header-container.scroll{
    position: relative;
    top: 235px;
}
.navbar-nav {
    align-items: center;
    z-index: 10;
    .nav-link {
        font-weight: 600;
        text-transform: initial;
        font-size: 1.15rem;
        color: #ffffff !important;
        &:hover {
            color: #fcbf24 !important;
        }

        @media(max-width: $sizeMd) {
            font-size: 0.78rem;
            margin-right: 0.8rem;
        }
    }
    .dropdown {
        span {
            font-weight: 600;
            text-transform: initial;
            font-size: 1.15rem;
            color: #ffffff !important;
        }
        a {
            font-weight: 600;
            text-transform: initial;
            font-size: 1.15rem;
            color: #ffffff !important;
        }
    }

    @media(max-width: $sizeMd){
        flex-direction: row;

    }
}

.bg-grayish{
    background-image: url("../../images/nav-bg.png");
}

.navbar-dark .navbar-toggler{
    color: transparent;
    border-color:transparent;
    cursor: pointer;
}

header .navbar-brand img{
    background-color: transparent!important;
    height: 8.25rem;
    padding-left:0!important;
    padding-right:0!important;
}
header .navbar:not(.scroll) .navbar-brand img{
    margin: 0.375rem auto;
}

header .navbar.scroll .navbar-brand img{
    padding:0!important;
    max-height: 70px!important;
}

header .navbar-brand{
    width: 22.8rem;
}


.navbar-nav .show>.nav-link,
.navbar-nav .dropdown a:hover{
    color: #fcbf24!important;
}

.navbar-nav .dropdown a:hover{
    background-color: transparent;
}

.dropdown-toggle::after{
    color: transparent;
}

.language{
    width: 2.188rem;
    height: 2.188rem;
    display:inline-block;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    vertical-align: bottom;
}

.language.lang-es{
    background-image: url('./../../images/language-es.png');
}

.language.lang-en{
    background-image: url('./../../images/language-en.png');
}

.acceso-glr{
    border: 0.063rem solid #fff;
    padding: 0.188rem 1.6rem;
    border-radius: 0.625rem;
    background-repeat: no-repeat;
    background-position: 0.625rem;

    @media(max-width: 992px){
        font-size: 0.8rem;
        padding: 0.188rem 1rem;
    }
}

.acceso-glr:hover{
    color: #000;
    background-color: #fcbf24;
    border-color: #fcbf24;
}

@media(max-width: 767px){
    header .navbar-brand img{
        height: 6.25rem;
    }
}

@media(max-width: 991px){
    header .container{
        flex-direction: row-reverse;
    }

    header .navbar-brand{
        flex: auto;
    }

    .navbar-dark .navbar-brand{
        display:flex;
        justify-content: center;
    }

    .navbar-nav .dropdown-menu{
        background-color: transparent;
        border: none;
        position: absolute;
        top:40px;
        background-image: url("../../images/nav-bg.png");
    }
}

@media(min-width: 992px){
    .navbar-expand-lg .navbar-collapse{
        align-content: flex-end;
    }

    .navbar-nav .nav-link:not(:last-child){
        margin-right: 1.25rem;
    }

    .navbar-nav .dropdown-menu{
        border: none;
        background-image: url("../../images/nav-bg.png");
    }

    .dropdown{
        display: flex;
        align-content: center;
        flex-direction: row;
    }
    .dropdown-toggle::after{
        display:none;
    }

}

.btn-check:focus+.btn, .btn:focus{
    box-shadow: none;
}

.main-header {
    min-height: 40.625rem;
    position: relative;
    background-size: cover;

    .text-container {
        h4 {
            font-size: 2.8rem;
            font-weight: 600;
            color: #ffffff !important;
            text-shadow: 0.125rem 0.188rem 0.188rem #1c1e20;
            font-family: Gotham, Arial;
            line-height: inherit;

            @media(max-width: $sizeSm) {
                font-size: 1.8rem;
                line-height: 1.3;
            }

        }

        .btn-filled {
            font-size: 1.3rem;
            display: inline-block;

            &.always-active {
                background-color: #fcbf24 !important;
                border-color: #fcbf24 !important;
                color: black !important;
            }

            &:not(:hover) {
                color: black !important;

                &:not(.always-active) {
                    border-color: #ffffff;
                    background-color: transparent;
                    color: #ffffff !important;
                }

                @media(max-width: $sizeMd) {
                    background-color: #fcbf24;
                    border-color: #fcbf24;
                    color: black !important;
                }
            }
        }
    }

    @media(max-width: $sizeSm){
        min-height: 46.875rem;
    }

    @media(min-width: $sizeSm) and (max-width: $sizeMd){
        min-height: 53.125rem;
    }

}

.nav-extra{
    background-image: url("../../images/menu-icon.png");
    width: 1.875rem;
    height: 1.875rem;
    background-color: transparent;
    border:none;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
}
.menu-toggle {
    position: absolute;
    width: 80vw;
    height: 0;
    overflow: hidden;
    transform: scaleY(0);
    transition: transform 400ms ease-in-out;
    z-index: 1000;

    @media(min-width: $sizeSm){
        width: 100%;
    }
    .nav-tabs {
        border: none;
        .nav-item.show {
            .nav-link {
                border: none !important;
            }
        }
        .nav-link.active {
            border: none !important;
        }
        .nav-link {
            &:active {
                border: none !important;
            }
            &:hover {
                border: none !important;
            }
        }
    }
    .nav-pills {

        .nav-link{
            font-size: 1.2rem;
            padding-top: 1rem;
            padding-bottom:1rem;
        }
        .nav-link.active {
            background-color: #2e2c2e;
            color: #fcbf24;
            border-radius: 0;
            @media(min-width: $sizeMd){
                background-color: #c9ccce;
                color: #fff;
            }
        }
        .show {
            >.nav-link {
                background-color: #c9ccce;
                color: #fff;
                border-radius: 0;
            }
        }
    }
    .nav-link {
        color: #c9ccce;
        border-radius: 0;
        font-size: 20px;
        font-weight: 500;

        @media(max-width: $sizeSm){
            background-color: #2e2c2e;
            color: #fff;
            font-size: 1rem;
        }
        @media(min-width: $sizeSm){
            margin-right: 25px;
            padding: 0px 8px;
        }
    }
    h6 {
        font-weight: 600;
        font-size: 18px;
        a{
            text-decoration: none;
        }
    }
    .menu-list {
        border-bottom: 1px solid #000;

        @media(min-width: $sizeMd){
            border-left: 1px solid #000;
            border-bottom: none;
            >div {
                padding: 0px 20px;
            }
        }
        padding: 20px 10px;
        a, div, span, h6{
            color: #212529!important;
        }
        ul {
            list-style: none;
            padding: 0;

            li {
                a{
                    text-decoration: none;
                }
                &:first-child {
                    margin-top: 0.938rem;
                }
                &:not(:last-child) {
                    margin-bottom: 0.938rem;
                }
            }

            @media(min-width: $sizeMd){
                margin: 0 0 30px 0;
            }
        }
        &:hover {
            * {
                color: #545556!important;
            }
            background-color: #c9ccce !important;
            border-color: #c9ccce !important;
        }
    }
}
.menu-toggle.toggled {
    background-color: #fff;
    transform: scaleY(1);
    transform-origin: top;
    border-bottom: 2px solid #000;
    @media(min-width: $sizeMd){
        height: 600px;
    }
    @media(max-width: $sizeMd){
        height: min-content!important;
    }
}
.menu-toggle.toggled.main-toggled {
    @media(min-width: $sizeMd){
        height: 550px;
        width: 100vw;
    }
}
.menu-toggle.scroll {
    position: fixed;
    z-index: 100;
    top: 95px;

    @media(max-width: $sizeMd){
        top: 9.8rem;
        height: min-content!important;
    }
}


#rodriguez-navbar{
    @media(max-width: $sizeMd){
        display: flex;
        align-content: center!important;
    }
    .second-menu{
        @media(max-width: $sizeMd){
            position: absolute;
            right: 0.938rem;
        }
    }
}


@media(max-width: $sizeMd){
    .menu-toggle{
        font-size: 1.1rem;
        .menu-list{
            padding: 1rem;

            &:last-of-type{
                border-bottom: 0;
            }

            ul{
                margin:0;
            }
        }
        h6{
            font-size: 1.2rem;
        }

        ul li:not(:last-child) {
            margin-bottom: 0.5rem!important;
        }

        & > div{
            line-height: 1.2;
        }
    }
}

.acceso-portal{
    h1 {
        font-family: Gotham, Arial, sans-serif;
        font-size: 2rem;
        color: #fff;
        font-weight: 600;

        @media(max-width: $sizeSm){
            font-size: 1.6rem;
        }
        span{
            color: #fcbf24;
            font-weight: 400;
        }
    }
}

.bg-dark-gray{
    background-color:#514e4a;
}