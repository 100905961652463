@import './../../variables.scss';

.contact-box {
  background-color: #76706c;
  border-radius: 10px;
  color: #fff;
}

.contact-box .form-control {
  background-color: #c4c2c0;
  color: #fff;
  border: none;
  box-shadow: 1px 2px 2px -1px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 1px 2px 2px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 2px 2px -1px rgba(0, 0, 0, 0.75);
  border-radius: 2px !important;
  padding: 10px;
}

.form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #fff;
}

.form-control::-moz-placeholder { /* Firefox 19+ */
  color: #fff;
}

.form-control:-ms-input-placeholder { /* IE 10+ */
  color: #fff;
}

.form-control:-moz-placeholder { /* Firefox 18- */
  color: #fff;
}

.contact-box .image {
  background-size: cover;
  background-position: center;
  border-radius: 10px 0 0 10px;
  background-image: url("/images/inicio/contact-box.png");
  @media(max-width: $sizeSm) {
    background-image: url("/images/inicio/contact-box-mobile.png");
    background-size: cover;
    border-radius: 0;
    height: 15rem;
    background-position: center;
  }
}

.cotiza-ahora {
  .options {
    font-size: 1.5rem;
    font-weight: 700;
    list-style: none;

    @media(max-width: $sizeSm){
      font-size: 0.98rem;
    }
  }
  @media(min-width: $sizeSm) {
    .text-yellow {
      color: #fff!important;
    }
  }

  .btn-filled {
    border-color: #fff;
    color: #fff;

    &:hover {
      background-color: #fcbf24!important;
      border-color: #fcbf24!important;
      color: #000;
    }

    background-color: transparent;
  }

  .image {
    border-radius: 0 10px 10px 0;
  }

  .owl-theme {
    .owl-dots {
      .owl-dot {
        span {
          background-color: #fff;
        }
      }
    }
  }

  .owl-dots {
    &:after {
      height: 100%;
      content: "";
      width: 100%;
      display: block;
      z-index: 100;
      position: absolute;
      top: 0;
    }
  }

}

.contact-box {
  h3 {
    font-size: 1.875rem !important;

    @media(max-width: $sizeXs) {
      font-size: 1.58rem !important;
      color: $yellow !important;
    }
  }

  p{
    @media(max-width: $sizeSm){
      font-size: 1rem;
      width: 80vw;
    }
  }

  .btn-filled {
    color: #ffffff;
    border-color: #ffffff;
    margin-bottom: 1.25rem;

    &:hover {
      border-color: #fcbf24;
      color: $yellow;
    }
  }
}

.main-header {
  &.inicio{
    min-height: 54.188rem;
    background-position: 30%;

    @media(max-width: $sizeMd) {
      min-height: 33rem;
    }


    @media(min-width: $sizeSm) and (max-width: $sizeMd) {
      min-height: 54.188rem;
    }
  }
}

@media(min-width: $sizeSm) and (max-width: $sizeMd) {
  .contact-box .image {
    min-height: 25rem;
  }
}

@media(min-width: $sizeMd) {
  .contact-box .image {
    min-height: 28.125rem;
  }
}

.bg-gray,
.cotiza-ahora {
  background-color: #8f9196;
}

.service-block .btn-filled {
  border-color: #ffffff;
  color: #fff !important;
  margin-top: 10px;
  width: max-content;
}

.service-block .btn-filled:hover {
  border-color: #fcbf24;
}

.service-block .btn-filled {
  font-size: 1.25rem;

  @media(max-width: 768px) {
    font-size: 1rem;
    padding: 0.2rem 0.4rem;
  }
}

.news {
  background-color: #ffffff;
  padding: 1.25rem 0 70px 0 !important;
}

.news .preview h6,
.news .preview h5 {
  font-weight: 700;
  font-size: 1.25rem;
  padding: 0;
  margin: 0;
}

.news .preview h5 {
  margin-bottom: 15px;
}

.news .owl-item.active:nth-child(1n + 2) .preview .details {
  border-left: 2px solid #76706c;
}

.news .owl-item .preview .details {
  height: 8rem;

  @media(min-width: $sizeMd){
    height: 9rem;
  }
}

.news .owl-item .preview .details .bottom-box {
  position: absolute;
  bottom: 0;
}

.news .preview:hover h6 {
  background-color: #76706c;
  border-radius: 10px 10px 0 0;
  color: #ffffff;
}

.news .preview .publish-date {
  font-weight: 600;
  color: #fcbf24;
  margin-top: 5px;
  display: block;
}

.news .preview h6 {
  padding: 10px;
  margin: 0 !important;
  width: 100%;
}

.news .preview .read-more {
  font-size: 14px !important;
  font-weight: 500;
  text-decoration: none;
}

.news .preview .read-more:after {
  content: "";
  width: 12px;
  height: 13px;
  font-size: 25px;
  margin-left: 5px;
  line-height: 0px;
  vertical-align: sub;
  background-size: contain;
  background-image: url("./../../images/icons/filled-btn-arrow-gray.png") !important;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
}

.service-block {
  min-height: 43vw;
  background-repeat: no-repeat;
  background-size: 46vw auto;
  background-position: center;
  transition: background-position 400ms;
  display: flex;
  align-content: center;
  justify-content: space-between;
  flex-direction: column;

  h3 {
    color: #ffffff;
    font-size: 2rem;
    line-height: 2rem;
    margin-bottom: 0rem;
    transition: margin 400ms;

    @media (max-width: $sizeMd) {
      font-size: 1.2rem;
      line-height: 1;
    }
  }

  .btn-filled {
    transition: margin 400ms;
  }

  @media (max-width: $sizeMd) {
    min-height: 20vw;
    background-repeat: no-repeat;
    background-size: 20vw auto;
  }
}

@media (min-width: $sizeMd) {
  .service-block:hover {
    background-position: center -2rem;
  }

  .service-block:hover h3 {
    margin-top: -2rem;
  }
  .service-block:hover .btn-filled {
    margin-bottom: 2rem;
    color: #34312c !important;
    transition-property: background, bottom;
  }
}

#service-one,
#service-two,
#service-three {
  transition: background 200ms;

  @media(max-width: $sizeMd) {
    background-size: cover;
  }
}

#service-one {
  background-image: url("/images/inicio/img-1.jpg");

  &:hover {
    background-image: url("/images/inicio/img-1-hover.jpg");
  }
}

#service-two {
  background-image: url("/images/inicio/img-2.jpg");

  &:hover {
    background-image: url("/images/inicio/img-2-hover.jpg");
  }
}

#service-three {
  background-image: url("/images/inicio/img-3.jpg");

  &:hover {
    background-image: url("/images/inicio/img-3-hover.jpg");
  }
}

@media(max-width: $sizeMd) {
  .contact-box {
    border-radius: 0 !important;
  }

  .service-block {
    min-height: 275px;
  }
}

@media(min-width: $sizeSm) and (max-width: $sizeMd) {
  .service-block {
    min-height: 500px;
  }
}

.tus-socios {

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media(max-width: $sizeMd){
    opacity: 0;
    &.scroll{
      opacity: 1;
      animation-name: fadeInOpacity;
      animation-iteration-count: 1;
      animation-timing-function: ease-in;
      animation-duration: 800ms;
    }
  }
  @media(min-width: $sizeMd){
    height: 300px;
    position: relative;

    h2 {
      font-size: 100px;
      font-family: Gotham, Arial;
      width: 670px;
      text-indent: 100px;
      line-height: 100px;
      margin: 0 auto;
      color: #fcbf24;
      opacity: 0;
      display: block;
      transition: opacity 1500ms ease-in-out;
    }

    &.scroll {
      h2 {
        opacity: 1;
      }

      &:before {
        width: calc(50% - 260px);
      }

      &:after {
        width: calc(50% - 90px);
      }
    }

    &:before {
      content: "";
      display: block;
      background-color: #fcbf24;
      width: 0;
      position: absolute;
      left: 0;
      top: 10px;
      height: 80px;
      transition: width 1000ms ease-in-out;
    }

    &:after {
      content: "";
      display: block;
      background-color: #fcbf24;
      width: 0;
      position: absolute;
      right: 0;
      height: 80px;
      bottom: 0;
      transition: width 1200ms ease-in-out;
    }
  }
}
