@import './../../variables.scss';

footer {
  background-image: url('/images/footer/footer-bg.png');

  background-color: #8f9196;
  color: #f2f2f1;

  .addresses{
    @media(max-width: $sizeMd){
      .col-8{
        max-width: 220px;
      }
    }
  }
}

footer ul {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
}

footer h5 {
  font-weight: 600;
}

footer .main-info {
  font-size: 1rem;
}

footer .main-info a {
  text-decoration: none;
  vertical-align: middle;
}

footer .main-info a:hover {
  color: #fdbf23 !important;
}

footer hr {
  background-color: #fff;
}

footer .address-info {
  font-size: 0.95rem;
  line-height: 1.125rem;
}

footer .address-info .address,
footer .address-info .phone {
  padding-left: 3.75rem;
  background-position: 10px 0;
  background-repeat: no-repeat;
  transition: background 1000ms ease-in-out;
}

footer .address-info .address {
  min-height: 3.75rem;
  background-image: none;
  background-size: 0;
}

footer .address-info .address.scroll {
  background-size: 1.063rem;
  background-image: url('./../../images/icons/marker-big-icon.png');
}

footer .address-info .phone {
  transition: background 1500ms ease-in-out;
  background-size: 0;
}

footer .address-info .phone.scroll {
  background-size: 1.375rem;
  background-image: url('./../../images/icons/phone-big-icon.png');
}

.icon-lists li {
  background-repeat: no-repeat;
  background-position: 0;
  padding-left: 1.75rem;
}

.icon-lists > li {
  transition: background 500ms ease-in-out;
  background-size: auto 0px;
}

.icon-lists > li:nth-child(1) {
  transition: background 800ms ease-in-out;
}

.icon-lists > li:nth-child(2) {
  transition: background 900ms ease-in-out;
}


.icon-lists > li:nth-child(3) {
  transition: background 1000ms ease-in-out;
}


.icon-lists > li {
  background-size: auto 1.125rem;
}

.icon-lists {
  .phone {
    @media(max-width: 768px) {
      &:before {
        display: inline-block;
        content: " ";
        width: 20px;
        vertical-align: text-top;
        height: 20px;
        margin-right: 5px;
        background-repeat: no-repeat;
        background-size: auto 1.125rem;
        background-position: center;
        background-image: url('./../../images/icons/phone-icon.png');
      }

      &:hover{
        &:before {
          background-image: url('./../../images/icons/phone-icon-active.png');
        }
      }
    }

    @media(min-width: 768px) {
      margin-bottom: 5px;
      background-image: url('./../../images/icons/phone-icon.png');
      &:hover {
        background-image: url('./../../images/icons/phone-icon-active.png');
        margin-bottom: 5px;
      }

    }
  }

  .working-hours {
    @media(max-width: 768px) {
      &:before {
        display: inline-block;
        content: " ";
        width: 20px;
        vertical-align: text-top;
        height: 20px;
        margin-right: 5px;
        background-repeat: no-repeat;
        background-size: auto 1.125rem;
        background-position: center;
        background-image: url('./../../images/icons/time-icon.png');
      }
    }

    @media(min-width: 768px) {
      background-image: url('./../../images/icons/time-icon.png');
    }
  }
}

.icon-lists .lang.scroll {
  background-size: auto 1.125rem !important;
}

.icon-lists .lang {
  background-size: auto 0;
  transition: background 400ms ease-in-out;
}

.icon-lists .info {
  background-image: url('./../../images/icons/info-icon.png');
}

.icon-lists .info:hover {
  background-image: url('./../../images/icons/info-icon-active.png');
}

.icon-lists .home-location {
  background-image: url("../../images/icons/home-location-icon.png");
}

.icon-lists .home-location:hover {
  background-image: url("../../images/icons/home-location-icon-active.png");
}

.icon-lists .at-email {
  background-image: url("../../images/icons/at-email-icon.png");
}

.icon-lists .at-email:hover {
  background-image: url("../../images/icons/at-email-icon-active.png");
}

.language-list li {
  width: 100%;
}

.language-list li a {
  border-bottom: 1px solid #f2f2f1;
  display: block;
}

.language-list li:hover a {
  border-bottom: 1px solid #fdbf23;
}

.language-list li:not(:hover).active {
  background-image: url('./../../images/icons/language-icon.png');
}

.language-list li:hover {
  background-image: url('./../../images/icons/language-icon-active.png');
}

.language-list li:hover + li {
  background-image: none !important;
  transition: background-image 800ms;
}

.copyright {
  background-color: #75777a;
  color: #f2f2f1;

  a {
    color: inherit;
    font-size: 0.95rem;

    @media(max-width: 768px) {
      padding-top: 0.95rem;
      display: block;
      text-decoration: none;

      &:after {
        content: " ";
        height: 1px;
        display: block;
        background-color: white;
        width: 132px;
        margin: 0.3rem auto 0 auto;
      }
    }
  }

  p {
    font-size: 0.95rem;
  }

  @media(max-width: 768px) {
    text-align: center;
    line-height: 1;
  }
}

.social-networks {
  color: #f2f2f1;
}

.social-networks ul {
  margin-top: 8px;
  margin-left: 0;
  padding-left: 0;
}

.social-networks ul li {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 5px;
}

.social-networks ul li a {
  display: block;
  width: 100%;
  height: 100%;
  background-size: cover;
  font-size: 0;
}

.social-networks ul li a.facebook {
  background-image: url('./../../images/icons/facebook.png');
}

.social-networks ul li a.instagram {
  background-image: url('./../../images/icons/instagram.png');
}

.social-networks ul li a.linkedin {
  background-image: url('./../../images/icons/linkedin.png');
}

.social-networks ul li a.youtube {
  background-image: url('./../../images/icons/youtube.png');
}

footer .social-networks ul li:not(:last-child) {
  margin-right: 25px;
}

footer .social-networks ul {
  line-height: 0;
}

footer .social-networks ul li {
  width: 27px;
  height: 27px;
}

footer .social-networks ul a {
  background-size: auto 25px;
  background-repeat: no-repeat;
  background-position: center;
}

footer .social-networks ul li a.facebook {
  background-image: url('./../../images/icons/fb-nb.png');
}

footer .social-networks ul li:hover a.facebook {
  background-image: url('./../../images/icons/fb-nb-active.png');
}

footer .social-networks ul li a.linkedin {
  background-image: url('./../../images/icons/ln-nb.png');
}

footer .social-networks ul li:hover a.linkedin {
  background-image: url('./../../images/icons/ln-nb-active.png');
}

footer .social-networks ul li a.youtube {
  background-size: 28px auto;
  background-image: url('./../../images/icons/youtube-nb.png');
  background-position: center 5px;
}

footer .social-networks ul li:hover a.youtube {
  background-image: url('./../../images/icons/youtube-nb-active.png');
}

footer .social-networks ul li a.twitter {
  background-size: 29px auto;
  background-position: center 4px;
  background-image: url('./../../images/icons/twitter-nb.png');
}

footer .social-networks ul li:hover a.twitter {
  background-image: url('./../../images/icons/twitter-nb-active.png');
}


.social-networks ul li:not(:last-child) {
  margin-right: 40px;
}

.small-logo {
  display: flex;
  align-items: center;
}

.footer-logo {
  max-height: 45px;
  height: auto;

  @media(max-width: 768px) {
    margin: 10px auto;
    display: block;
  }
}

@media(min-width: 768px) {

  .mb-md-3-half {
    margin-bottom: 2.25rem;
  }
  .mt-md-4-half {
    margin-top: 2.25rem;
  }
}

@media(min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
}